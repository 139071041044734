const fontTypes = [
  {
    name: "default",
    value: "Inter,sans-serif",
  },
  {
    name: "Arial",
    value: "Arial,Helvetica,sans-serif",
  },
  {
    name: "Comic Sans MS",
    value: "Comic Sans MS,cursive",
  },
  {
    name: "Courier New",
    value: "Courier New,Courier,monospace",
  },
  {
    name: "Georgia",
    value: "Georgia,serif",
  },
  {
    name: "Lucida Sans Unicode",
    value: "Lucida Sans Unicode,Lucida Grande,sans-serif",
  },
  {
    name: "Tahoma",
    value: "Tahoma,Geneva,sans-serif",
  },
  {
    name: "Times New Roman",
    value: "Times New Roman,Times,serif",
  },
  {
    name: "AriTrebuchet MSal",
    value: "Trebuchet MS,Helvetica,sans-serif",
  },
  {
    name: "Verdana",
    value: "Verdana,Geneva,sans-serif",
  },
];
const fontSizes = [
  8, 9, 10, 11, 12, 14, 16, 18, 20, 22, 24, 26, 28, 36, 48, 72,
];

export default {
  fontTypes,
  fontSizes,
};
